import React from "react";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import logo from "../assets/logo-kv.svg";

// import mui
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import TelegramIcon from "@mui/icons-material/Telegram";
import EmailIcon from "@mui/icons-material/Email";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Tooltip from "@mui/material/Tooltip";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { Facebook, Instagram } from "@material-ui/icons";
import SideBox from "./SideBox";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Box className="footer">
        <Container maxWidth="lg">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid container>
              <Grid item xs={6} md={4} className="img_container">
                <img src={logo} alt="logo" title="logo" />
              </Grid>
              <Grid item xs={6} md={4} className="footer_links_container">
                <Box style={{ marginLeft: "10px", color: "black" }}>
                  <h2>{t("footer.menu")}</h2>
                </Box>
                <ul>
                  <li>
                    <Link className="footer_link" to="/">
                      {t("navbar.home")}
                    </Link>
                  </li>
                  <li>
                    <Link className="footer_link" to="/news">
                      {t("navbar.news")}
                    </Link>
                  </li>
                  <li>
                    <Link className="footer_link" to="/about">
                      {t("navbar.about")}
                    </Link>
                  </li>
                  <li>
                    <Link className="footer_link" to="/employer">
                      {t("navbar.employer")}
                    </Link>
                  </li>
                  <li>
                    <Link className="footer_link" to="/students">
                      {t("navbar.students")}
                    </Link>
                  </li>
                  <li>
                    <Link className="footer_link" to="/contact">
                      {t("navbar.contact")}
                    </Link>
                  </li>
                  <li>
                    <Link className="footer_link" to="/shop">
                      {t("navbar.shop")}
                    </Link>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box className="social-media_container">
                  <h2>{t("footer.contact")}</h2>
                  <ul>
                    <li>
                      <a
                        href="tel:+4917643656708"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <PhoneIcon />
                      </a>
                    </li>
                    <li>
                      <a
                        href="mailto:info@jas-vermittlung.de"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <EmailIcon />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://wa.me/+4917643656708"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <WhatsAppIcon />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://t.me/+4917643656708"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <TelegramIcon />
                      </a>
                    </li>
                  </ul>
                </Box>
                <Box className="social-media_container">
                  <h2>{t("footer.contact1")}</h2>
                  <ul>
                    <li>
                      <a
                        href="https://www.youtube.com/@jas-vermittlung"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <YouTubeIcon />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/jasvermittlung"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Instagram />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/profile.php?id=61550482852644"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Facebook />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://t.me/jasvermittlung"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <TelegramIcon />
                      </a>
                    </li>
                  </ul>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Grid container className="footer_small_box">
          <Grid item md={1}></Grid>
          <Grid item md={4}>
            <div style={{ color: "white" }}>
              Copyright © {new Date().getFullYear()}. JAS-Vermittlung.
              {" " + t("footer.rights")}
            </div>
          </Grid>
          <Grid item md={1}></Grid>
          <Grid item md={4}>
            <Link className="footer_link_bottom" to="/imprint">
              {t("footer.imprint")}
            </Link>
            <Link className="footer_link_bottom" to="/data-protection">
              {t("footer.dataProtection")}
            </Link>
          </Grid>
          <Grid item md={2}>
            {/* <span className="author">{t("footer.madeBy")} @MohirTech</span> */}
          </Grid>
        </Grid>
      </Box>

      <SideBox />
    </div>
  );
};

export default Footer;
