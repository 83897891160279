import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

// import images
import waiter from "../assets/waiter1.jpg";
import maid from "../assets/hotelmaid1.jpg";
import banner from "../assets/banner1_1.png";
import student4 from "../assets/student2.jpg";
import employer from "../assets/images/employer.jpg";
import services from "../assets/images/services.jpg";
import applicant from "../assets/images/applicant.jpg";

// import mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import JCard from "../components/JCard";
import Banner from "../components/Banner";
import JButton from "../components/JButton";
import Container from "@mui/material/Container";
import CardContent from "@mui/material/CardContent";
import Achievement from "../components/Achievement";
import Partners from "../components/Partners";

// component
const Home = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.location.origin !== "https://www.jas-vermittlung.de") {
      window.location.replace("https://www.jas-vermittlung.de");
    }
  }, []);

  return (
    <div>
      <Box>
        <Banner
          src={banner}
          imageName="Big field"
          title="banner.title"
          subtitle="banner.subtitle"
          nextContainerId="main"
        />
        {/* <MyCarousel /> */}
      </Box>
      <Box id="main" className="main_container">
        <Container maxWidth="lg">
          <Box className="page_title">
            <h1>JAS-Vermittlung</h1>
            <h2>{t("home.subtitle")}</h2>
            <div className="text_container">{t("home.text1")}</div>
          </Box>
        </Container>
        <Container maxWidth="lg">
          <Box className="section_container">
            <Box className="title">
              <div>{t("home.title1")}</div>
            </Box>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <Card className="card">
                  <Box className="card_black_cover"></Box>
                  <img
                    src={student4}
                    alt="student"
                    title="student"
                    style={{ height: "auto", width: "100%" }}
                  />
                  <CardContent className="card_content">
                    <div className="card_title">{t("home.farm")}</div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={4}>
                <Card className="card">
                  <Box className="card_black_cover"></Box>
                  <img
                    src={maid}
                    alt="student"
                    title="student"
                    style={{ height: "auto", width: "100%" }}
                  />
                  <CardContent className="card_content">
                    <div className="card_title">{t("home.hotel")}</div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={4}>
                <Card className="card">
                  <Box className="card_black_cover"></Box>
                  <img
                    src={waiter}
                    alt="student"
                    title="student"
                    style={{ height: "auto", width: "100%" }}
                  />
                  <CardContent className="card_content">
                    <div className="card_title">{t("home.restaurant")}</div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Container maxWidth="lg">
          <Box className="section_container">
            <Box className="title">
              <div>{t("home.title2")}</div>
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "normal",
                  textAlign: "justify",
                  paddingTop: "20px",
                }}
              >
                {t("home.text2")}
              </div>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <JCard
                  image={employer}
                  title="home.employer1"
                  subtitle=""
                  to="/employer"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <JCard
                  image={applicant}
                  title="home.student1"
                  subtitle=""
                  to="/students"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <JCard
                  image={services}
                  title="home.services"
                  subtitle=""
                  to="/services"
                />
              </Grid>
            </Grid>
          </Box>
        </Container>

        <Achievement />

        <Partners />

        <Container maxWidth="lg">
          <Box className="btn_container">
            <JButton type="primary" text="banner.contact" to="contact" />
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default Home;
