// import React, { useEffect } from "react";
// import { useTranslation } from "react-i18next";

// import contact from "../assets/contact.jpg";

// // import mui
// import Box from "@mui/material/Box";
// import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";
// import AccountBoxIcon from "@mui/icons-material/AccountBox";
// import LocationCityIcon from "@mui/icons-material/LocationCity";
// import PhoneIcon from "@mui/icons-material/Phone";
// import EmailIcon from "@mui/icons-material/Email";
// import Banner from "../components/Banner";
// import { Typography } from "@mui/material";

// // component
// const Contact = () => {
//   const { t } = useTranslation();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   return (
//     <div>
//       {/* <Box>
//         <Banner
//           src={contact}
//           imageName="Restaurant"
//           title="banner.title"
//           subtitle="banner.subtitle"
//           nextContainerId="contact"
//         />
//       </Box> */}
//       <Container id="contact" maxWidth="lg" className="contact_container">
//         <Box className="page_title">
//           <Box className="contact_title">
//             <span>{t("contact.title")}</span>
//           </Box>
//         </Box>

//         <Typography
//           variant="h4"
//           align="center"
//           style={{ marginBottom: "20px" }}
//         >
//           {t("contact.inDe")}
//         </Typography>

//         <Grid container direction="row" justifyContent="center" spacing={5}>
//           <Grid item>
//             <Box className="contact_box">
//               <h2>
//                 <AccountBoxIcon className="contact_icon" /> Jasur Nematjonov
//               </h2>
//               <h4>
//                 <PhoneIcon className="contact_icon" />
//                 <a href="tel:+4917643656708">+49 (0) 176 43 656 708</a>
//               </h4>
//               <h4>
//                 <PhoneIcon className="contact_icon" />
//                 <a href="tel:+4972217714767">+49 (0) 722 17 714 767</a>
//               </h4>
//               <h4>
//                 <EmailIcon className="contact_icon" />
//                 <a href="mailto:info@jas-vermittlung.de">
//                   info@jas-vermittlung.de
//                 </a>
//                 {/* <a href="mailto:n.jasur@web.de">n.jasur@web.de</a> */}
//               </h4>
//             </Box>
//           </Grid>
//           {/* <Grid item>
//             <Box className="contact_box">
//               <h2>
//                 <AccountBoxIcon className="contact_icon" /> Behzod Yuldashev
//               </h2>
//               <h4>
//                 <PhoneIcon className="contact_icon" />
//                 <a href="tel:+491782454278">+49 (0) 178 24 542 78</a>
//               </h4>
//               <h4>
//                 <EmailIcon className="contact_icon" />
//                 <a href="mailto:info@jas-vermittlung.de">
//                   info@jas-vermittlung.de
//                 </a>
//               </h4>
//             </Box>
//           </Grid> */}

//           <Grid item>
//             <Box className="contact_box">
//               <h2>{t("contact.address")}</h2>
//               {/* <h4>JAS Vermittlung</h4>
//               <h4> Geroldsauerstraße 16</h4>
//               <h4>76534 Baden-Baden</h4> */}
//               <h4>{t("contact.address11")}</h4>
//               <h4>{t("contact.address12")}</h4>
//               <h4>{t("contact.address13")}</h4>
//             </Box>
//           </Grid>
//         </Grid>

//         <Typography variant="h4" align="center" style={{ margin: "30px 0" }}>
//           {t("contact.inUz")}
//         </Typography>

//         <Grid container direction="row" justifyContent="center" spacing={5}>
//           <Grid item>
//             <Box className="contact_box">
//               <h2>
//                 <AccountBoxIcon className="contact_icon" />
//                 Sultonbek Qosimov
//               </h2>
//               <h4>
//                 <LocationCityIcon className="contact_icon" />
//                 Toshkent
//               </h4>
//               <h4>
//                 <PhoneIcon className="contact_icon" />
//                 <a href="tel:+998994654815">+998 99 2004815</a>
//               </h4>
//               <h4>
//                 <EmailIcon className="contact_icon" />
//                 <a href="mailto:sultonbek_germany@mail.ru">
//                   sultonbek_germany@mail.ru
//                 </a>
//               </h4>
//             </Box>
//           </Grid>
//           <Grid item>
//             <Box className="contact_box">
//               <h2>{t("contact.address")}</h2>
//               {/* <h4>JAS DEUTSCH o'quv markazi</h4>
//               <h4>207 - xona</h4>
//               <h4>AQXAI GEM binosi</h4> */}
//               <h4>{t("contact.address21")}</h4>
//               <h4>{t("contact.address22")}</h4>
//               <h4>{t("contact.address23")}</h4>
//             </Box>
//           </Grid>
//         </Grid>

//         <Grid container direction="row" justifyContent="center" spacing={5}>
//           <Grid item>
//             <Box className="contact_box">
//               <h2>
//                 <AccountBoxIcon className="contact_icon" />
//                 Shahobiddin Abdufattoxov
//               </h2>
//               <h4>
//                 <LocationCityIcon className="contact_icon" />
//                 Andijon
//               </h4>
//               <h4>
//                 <PhoneIcon className="contact_icon" />
//                 <a href="tel:+998994654815">+998 94 3327401</a>
//               </h4>
//               <h4>
//                 <EmailIcon className="contact_icon" />
//                 <a href="mailto:shahobdn1@gmail.com">shahobdn1@gmail.com</a>
//               </h4>
//             </Box>
//           </Grid>
//         </Grid>

//         <Grid container direction="row" justifyContent="center" spacing={5}>
//           {/* <Grid item> */}
//           <Box className="contact_box">
//             <h2>{t("contact.address")}</h2>
//             {/* <h4>JAS DEUTSCH o'quv markazi</h4>
//               <h4>207 - xona</h4>
//               <h4>AQXAI GEM binosi</h4> */}
//             <h4>{t("contact.address21")}</h4>
//             <h4>{t("contact.address22")}</h4>
//             <h4>{t("contact.address23")}</h4>
//           </Box>
//           {/* </Grid> */}
//         </Grid>
//       </Container>
//     </div>
//   );
// };

// export default Contact;

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Grid, Paper, Container } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import PhoneIcon from "@mui/icons-material/Phone";

const ContactBox = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container id="contact" maxWidth="lg" className="contact_container">
      <Box className="page_title">
        <Box className="contact_title">
          <span>{t("contact.title")}</span>
        </Box>
      </Box>

      <Typography variant="h4" align="center" style={{ marginBottom: "20px" }}>
        {t("contact.inDe")}
      </Typography>

      <Paper
        elevation={3}
        sx={{ p: 2, mx: "auto", my: 2, borderRadius: "20px" }}
      >
        <Grid container spacing={2}>
          {/* First Column */}
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="center" mb={2}>
              <AccountBoxIcon sx={{ mr: 1, color: "#3c5132" }} />
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Jasur Nematjonov
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <LocationCityIcon sx={{ mr: 1, color: "#3c5132" }} />
              <Typography variant="h6">Baden-Baden</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <PhoneIcon sx={{ mr: 1, color: "#3c5132" }} />
              <a
                href="tel:+4917643656708"
                style={{ color: "black", textDecoration: "none" }}
              >
                <Typography variant="h6">+49 (0) 176 43 656 708</Typography>
              </a>
            </Box>
            <Box display="flex" alignItems="center">
              <PhoneIcon sx={{ mr: 1, color: "#3c5132" }} />
              <a
                href="tel:+4972217714767"
                style={{ color: "black", textDecoration: "none" }}
              >
                <Typography variant="h6">+49 (0) 722 17 714 767</Typography>
              </a>
            </Box>
            <Box display="flex" alignItems="center">
              <EmailIcon sx={{ mr: 1, color: "#3c5132" }} />
              <a
                href="mailto:info@jas-vermittlung.de"
                style={{ color: "black", textDecoration: "none" }}
              >
                <Typography variant="h6">info@jas-vermittlung.de</Typography>
              </a>
            </Box>
          </Grid>

          {/* Second Column */}
          <Grid item xs={12} md={6}>
            <Typography
              variant="h5"
              component="div"
              mb={2}
              sx={{ fontWeight: "bold" }}
            >
              {t("contact.address")}
            </Typography>
            <Typography variant="h6">{t("contact.address11")}</Typography>
            <Typography variant="h6">{t("contact.address12")}</Typography>
            <Typography variant="h6">{t("contact.address13")}</Typography>
          </Grid>
        </Grid>
      </Paper>

      <Typography variant="h4" align="center" style={{ margin: "30px 0" }}>
        {t("contact.inUz")}
      </Typography>

      <Paper
        elevation={3}
        sx={{ p: 2, mx: "auto", my: 2, borderRadius: "20px" }}
      >
        <Grid container spacing={2}>
          {/* First Column */}
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="center" mb={2}>
              <AccountBoxIcon sx={{ mr: 1, color: "#3c5132" }} />
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Sultonbek Qosimov
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <LocationCityIcon sx={{ mr: 1, color: "#3c5132" }} />
              <Typography variant="h6">Toshkent</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <PhoneIcon sx={{ mr: 1, color: "#3c5132" }} />
              <a
                href="tel:+998992004815"
                style={{ color: "black", textDecoration: "none" }}
              >
                <Typography variant="h6">+998 99 2004815</Typography>
              </a>
            </Box>

            <Box display="flex" alignItems="center">
              <EmailIcon sx={{ mr: 1, color: "#3c5132" }} />
              <a
                href="mailto:sultonbek_germany@mail.ru"
                style={{ color: "black", textDecoration: "none" }}
              >
                <Typography variant="h6">sultonbek_germany@mail.ru</Typography>
              </a>
            </Box>
          </Grid>

          {/* Second Column */}
          <Grid item xs={12} md={6}>
            <Typography
              variant="h5"
              component="div"
              mb={2}
              sx={{ fontWeight: "bold" }}
            >
              {t("contact.address")}
            </Typography>
            <Typography variant="h6">{t("contact.address31")}</Typography>
            <Typography variant="h6">{t("contact.address32")}</Typography>
            {/* <Typography variant="h6">{t("contact.address13")}</Typography> */}
          </Grid>
        </Grid>
      </Paper>

      <Paper
        elevation={3}
        sx={{ p: 2, mx: "auto", my: 2, borderRadius: "20px" }}
      >
        <Grid container spacing={2}>
          {/* First Column */}
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="center" mb={2}>
              <AccountBoxIcon sx={{ mr: 1, color: "#3c5132" }} />
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Shahobiddin Abdufattoxov
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <LocationCityIcon sx={{ mr: 1, color: "#3c5132" }} />
              <Typography variant="h6"> Andijon</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <PhoneIcon sx={{ mr: 1, color: "#3c5132" }} />
              <a
                href="tel:+998943327401"
                style={{ color: "black", textDecoration: "none" }}
              >
                <Typography variant="h6">+998 94 3327401</Typography>
              </a>
            </Box>

            <Box display="flex" alignItems="center">
              <EmailIcon sx={{ mr: 1, color: "#3c5132" }} />
              <a
                href="mailto:shahobdn1@gmail.com"
                style={{ color: "black", textDecoration: "none" }}
              >
                <Typography variant="h6">shahobdn1@gmail.com</Typography>
              </a>
            </Box>
          </Grid>

          {/* Second Column */}
          <Grid item xs={12} md={6}>
            <Typography
              variant="h5"
              component="div"
              mb={2}
              sx={{ fontWeight: "bold" }}
            >
              {t("contact.address")}
            </Typography>
            <Typography variant="h6">{t("contact.address21")}</Typography>
            <Typography variant="h6">{t("contact.address22")}</Typography>
            <Typography variant="h6">{t("contact.address23")}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default ContactBox;
