import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import banner from "../assets/banner2.jpg";
import logo from "../assets/logo-kv.svg";

import photo1 from "../assets/gallery/photo1.jpg";
import photo2 from "../assets/gallery/photo2.jpg";
import photo3 from "../assets/gallery/photo3.jpg";
import photo4 from "../assets/gallery/photo4.jpg";
import photo5 from "../assets/gallery/photo5.jpg";
import photo6 from "../assets/gallery/photo6.jpg";
import photo7 from "../assets/gallery/photo7.jpg";
import photo8 from "../assets/gallery/photo8.jpg";
import photo9 from "../assets/gallery/photo9.jpg";
import photo10 from "../assets/gallery/photo10.jpg";
import photo11 from "../assets/gallery/photo11.jpg";
import photo12 from "../assets/gallery/photo12.jpg";
import photo13 from "../assets/gallery/photo13.jpg";
import photo14 from "../assets/gallery/photo14.jpg";
import photo15 from "../assets/gallery/photo15.jpg";
import photo16 from "../assets/gallery/photo16.jpg";
import photo17 from "../assets/gallery/photo17.jpg";
import photo18 from "../assets/gallery/photo18.jpg";
import photo19 from "../assets/gallery/photo19.jpg";
import photo20 from "../assets/gallery/photo20.jpg";
import photo21 from "../assets/gallery/photo21.jpg";
import photo22 from "../assets/gallery/photo22.jpg";
import photo23 from "../assets/gallery/photo23.jpg";
import photo24 from "../assets/gallery/photo24.jpg";
import photo25 from "../assets/gallery/photo25.jpg";
import photo26 from "../assets/gallery/photo26.jpg";

// import mui
import Box from "@mui/material/Box";
import Banner from "../components/Banner";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Achievement from "../components/Achievement";
import JButton from "../components/JButton";
import JGallery from "../components/JGallery";
import Partners from "../components/Partners";

// component
const About = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const images = [
    photo1,
    photo2,
    photo5,
    photo6,
    photo7,
    photo8,
    photo9,
    photo10,
    photo11,
    photo12,
    photo13,
    photo14,
    photo15,
    photo16,
    photo17,
    photo18,
    photo19,
    photo20,
    photo21,
    photo22,
    photo23,
    photo24,
    photo25,
    photo26,
  ];

  return (
    <div>
      {/* <Banner
        src={banner}
        imageName="Big field"
        title="banner.title"
        subtitle="banner.subtitle"
        nextContainerId="about"
      /> */}
      <Box id="about" className="main_container">
        <Container maxWidth="lg">
          <Box className="page_title">
            <h2>JAS-Vermittlung</h2>
            <h2>{t("about.subtitle")}</h2>

            <div className="text_container">{t("about.text")}</div>
          </Box>

          <Grid container spacing={2}>
            <Grid item md={8}>
              <Box className="list_title">
                <h2>{t("about.todo.title")}</h2>
              </Box>
              <div className="list_container">
                <ul>
                  <li>
                    <CheckCircle className="list_icon" />
                    {t("about.todo.text1")}
                  </li>
                  <li>
                    <CheckCircle className="list_icon" />
                    {t("about.todo.text2")}
                  </li>
                  <li>
                    <CheckCircle className="list_icon" />
                    {t("about.todo.text3")}
                  </li>
                  <li>
                    <CheckCircle className="list_icon" />
                    {t("about.todo.text4")}
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item md={4}>
              <Box className="img_container">
                <img src={logo} alt="student" title="student" />
              </Box>
            </Grid>
          </Grid>

          <Box className="page_title">
            <div className="text_container">{t("home.text1")}</div>
            <div className="text_container">{t("about.text1")}</div>
          </Box>
        </Container>

        <Container maxWidth="lg">
          <div className="youtube-box">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/MZ_NQhrTIX0?si=--z-NPCcNkj4ENG4"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen="allowFullScreen"
              frameBorder="0"
            ></iframe>
          </div>
        </Container>

        <Container maxWidth="lg">
          <div className="youtube-box">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/suv06rUA478?si=jlfm4nUCXODvvwrs"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowFullScreen="allowFullScreen"
            ></iframe>
          </div>
        </Container>

        <JGallery images={images} />

        <Achievement />

        <Partners />

        <Box className="btn_container">
          <JButton type="primary" text="banner.contact" to="/contact" />
        </Box>
      </Box>
    </div>
  );
};

export default About;
