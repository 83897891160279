import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import student from "../assets/student3.png";
import waiter from "../assets/waiter1.jpg";
import resturant from "../assets/restaurant.jpg";
import student4 from "../assets/student2.png";
import maid from "../assets/hotelmaid1.jpg";
import weiterbildung from "../assets/images/weiterbildung.jpg";
import workandtravel from "../assets/images/workandtravel.jpg";
import traktor from "../assets/images/traktors.jpg";
import field from "../assets/images/field.jpg";
import applicant1 from "../assets/images/applicant1.jpg";
import student2 from "../assets/images/student2.jpg";
import student3 from "../assets/images/student3.jpg";

import photo1 from "../assets/gallery/photo1.jpg";
import photo2 from "../assets/gallery/photo2.jpg";
import photo3 from "../assets/gallery/photo3.jpg";
import photo4 from "../assets/gallery/photo4.jpg";
import photo5 from "../assets/gallery/photo5.jpg";
import photo6 from "../assets/gallery/photo6.jpg";
import photo7 from "../assets/gallery/photo7.jpg";
import photo8 from "../assets/gallery/photo8.jpg";
import photo9 from "../assets/gallery/photo9.jpg";
import photo10 from "../assets/gallery/photo10.jpg";
import photo11 from "../assets/gallery/photo11.jpg";
import photo12 from "../assets/gallery/photo12.jpg";
import photo13 from "../assets/gallery/photo13.jpg";
import photo14 from "../assets/gallery/photo14.jpg";
import photo15 from "../assets/gallery/photo15.jpg";
import photo16 from "../assets/gallery/photo16.jpg";
import photo17 from "../assets/gallery/photo17.jpg";
import photo18 from "../assets/gallery/photo18.jpg";
import photo19 from "../assets/gallery/photo19.jpg";
import photo20 from "../assets/gallery/photo20.jpg";
import photo21 from "../assets/gallery/photo21.jpg";
import photo22 from "../assets/gallery/photo22.jpg";
import photo23 from "../assets/gallery/photo23.jpg";
import photo24 from "../assets/gallery/photo24.jpg";
import photo25 from "../assets/gallery/photo25.jpg";
import photo26 from "../assets/gallery/photo26.jpg";

// import mui
import Box from "@mui/material/Box";
import Banner from "../components/Banner";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import JCard from "../components/JCard";
import JButton from "../components/JButton";
import JGallery from "../components/JGallery";
import Partners from "../components/Partners";

const Students = () => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setExpanded("panel1");
  }, []);

  const images = [
    photo1,
    photo2,
    photo5,
    photo6,
    photo7,
    photo8,
    photo9,
    photo10,
    photo11,
    photo12,
    photo13,
    photo14,
    photo15,
    photo16,
    photo17,
    photo18,
    photo19,
    photo20,
    photo21,
    photo22,
    photo23,
    photo24,
    photo25,
    photo26,
  ];

  return (
    <div>
      <Box id="students" className="main_container">
        <Container maxWidth="lg">
          <Box className="page_title">
            {/* <h2>Talabalar uchun xorijda imkoniyatlar, shoshiling talabalar!</h2> */}
            <h2>{t("students.title")}</h2>

            <div className="text_container">{t("students.text")}</div>
          </Box>

          <Box className="section_container">
            <Box className="title">
              <div>{t("students.title3")}</div>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <JCard
                  image={workandtravel}
                  title="WORK & TRAVEL"
                  subtitle="students.subtitle1"
                  to={false}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <JCard
                  image={field}
                  title="FACHPRAKTIKUM"
                  subtitle="students.subtitle2"
                  to={false}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <JCard
                  image={traktor}
                  title="FACHPRAKTIKUM"
                  subtitle="students.subtitle3"
                  to={false}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <JCard
                  image={weiterbildung}
                  title="WEITERBILDUNG"
                  subtitle="students.subtitle4"
                  to={false}
                />
              </Grid>
            </Grid>
          </Box>

          <Box style={{ margin: "20px 0" }}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              style={{
                // borderRadius: "30px",
                margin: "10px 0",
                // boxShadow: "0px 2px 5px 2px #7fc348",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                style={{ backgroundColor: "#e1f5e3" }}
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  <h3>WORK & TRAVEL</h3>
                </Typography>
                <Typography
                  sx={{ color: "text.secondary" }}
                  className="accordion-subtitle"
                >
                  <h4>{t("students.subtitle1")}</h4>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="list_container" style={{ color: "black" }}>
                  <ul>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.list1.text1")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.list1.text2")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.list1.text3")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.list1.text4")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.list1.text5")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.list1.text6")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.list1.text7")}
                    </li>
                    {/* <li>
                      <CheckCircle className="list_icon" />
                      1,2,3 Kurs Talabalari uchun
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      Iyun, Iyul, August
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      Qishloq xo'jaligi amaliyotidan iborat (Sabzavotchilik,
                      Bog'dorchilik, Dehqonchilik, Issiqxona, Dala ishlari)
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      Ish haqi: 12,41 yevro/soat yoki ishbay
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      Haftasiga 40 soatlik ish-amaliyoti
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      Yashash joyi bilan taminlanadi (oyiga 300 yevrogacha
                      to'lov)
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      Nemis yoki Ingliz tili (A1-A2 daraja)
                    </li> */}
                  </ul>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              style={{
                // borderRadius: "30px",
                margin: "10px 0",
                // boxShadow: "0px 2px 5px 2px #7fc348",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
                style={{ backgroundColor: "#e1f5e3" }}
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  <h3>FACHPRAKTIKUM</h3>
                </Typography>
                <Typography
                  sx={{ color: "text.secondary" }}
                  className="accordion-subtitle"
                >
                  <h4>{t("students.subtitle2")}</h4>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="list_container" style={{ color: "black" }}>
                  <ul>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.list2.text1")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.list2.text2")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.list2.text3")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.list2.text4")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.list2.text5")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.list2.text6")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.list2.text7")}
                    </li>

                    {/* <li>
                      <CheckCircle className="list_icon" />
                      Faqat 3-kurslar uchun
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      Apreldan Iyungacha
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      Qishloq xo'jaliklaridagi amaliyotdan
                      iborat(Sabzavotchilik, Mevachilik, Dexqonchilik,
                      Issiqxona, Dala ishlari)
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      Ish haqi: 12,41 yevro/soat yoki ishbay
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      Haftasiga 40 soatlik ish-amaliyoti
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      Yashash joyi bilan taminlanadi (oyiga 300 yevrogacha
                      to'lov)
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      Nemis yoki Ingliz tili (A1-A2 daraja)
                    </li> */}
                  </ul>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
              style={{
                // borderRadius: "30px",
                margin: "10px 0",
                // boxShadow: "0px 2px 5px 2px #7fc348",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
                style={{ backgroundColor: "#e1f5e3" }}
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  <h3>FACHPRAKTIKUM</h3>
                </Typography>
                <Typography
                  sx={{ color: "text.secondary" }}
                  className="accordion-subtitle"
                >
                  <h4>{t("students.subtitle3")}</h4>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="list_container" style={{ color: "black" }}>
                  <ul>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.list3.text1")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.list3.text2")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.list3.text3")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.list3.text4")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.list3.text5")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.list3.text6")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.list3.text7")}
                    </li>
                    {/* <li>
                      <CheckCircle className="list_icon" />
                      Faqat 3-kurslar uchun
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      Qishloq xo'jaliklaridagi amaliyotdan
                      iborat(Sabzavotchilik, Mevachilik, Dexqonchilik,
                      Issiqxona, Dala ishlari)
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      Ish haqi: 12,41 yevro/soat yoki ishbay
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      Haftasiga 40 soatlik ish-amaliyoti
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      Yashash joyi bilan taminlanadi (oyiga 300 yevrogacha
                      to'lov)
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      Nemis yoki Ingliz tili (A1-A2 daraja)
                    </li> */}
                  </ul>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
              style={{
                // borderRadius: "30px",
                margin: "10px 0",
                // boxShadow: "0px 2px 5px 2px #7fc348",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
                style={{ backgroundColor: "#e1f5e3" }}
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  <h3>WEITERBILDUNG</h3>
                </Typography>
                <Typography
                  sx={{ color: "text.secondary" }}
                  className="accordion-subtitle"
                >
                  <h4>{t("students.subtitle4")}</h4>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="list_container" style={{ color: "black" }}>
                  <ul>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.list4.text1")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.list4.text2")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.list4.text3")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.list4.text4")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.list4.text5")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.list4.text6")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.list4.text7")}
                    </li>
                    {/* <li>
                      <CheckCircle className="list_icon" />
                      Bakalavr diplomi borlar uchun
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      Bir yil davomida
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      Qishloq xo'jaligida (Chorvachilik, Bog'dorchilik,
                      Parrandachilik, Yilqichilik, Dehqonchilik)
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      Ish haqi: 12,41 yevro/soat yoki ishbay
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      Haftasiga 40 soatlik ish-amaliyoti
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      Yashash joyi bilan taminlanadi (oyiga 300 yevrogacha
                      to'lov)
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      Nemis yoki Ingliz tili (A1-A2 daraja)
                    </li> */}
                  </ul>
                </div>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box className="section_container">
            <Box className="page_title">
              <Box className="title">
                <div>{t("students.title1")}</div>
              </Box>
            </Box>
            <Grid container spacing={2}>
              <Grid item md={8}>
                <div className="list_container">
                  <ul>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.advantages.text1")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.advantages.text2")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.advantages.text3")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.advantages.text4")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.advantages.text5")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.advantages.text6")}
                    </li>
                  </ul>
                </div>
              </Grid>
              <Grid item md={4}>
                <Box className="img_container">
                  <img src={student2} alt="student" title="student" />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Container maxWidth="lg">
          <Box className="section_container">
            <Box className="title">
              <div>{t("students.title2")}</div>
            </Box>
            <Grid container spacing={2}>
              <Grid item md={8}>
                <div className="list_container">
                  <ul>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.requirement.text1")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.requirement.text2")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.requirement.text3")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.requirement.text4")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.requirement.text5")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.requirement.text6")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("students.requirement.text7")}
                    </li>
                  </ul>
                </div>
              </Grid>
              <Grid item md={4}>
                <Box className="img_container">
                  <img src={student3} alt="student" title="student" />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <JGallery images={images} />
        </Container>

        <Partners />

        {/* <div className="bg-image-full-1">
          <Container maxWidth={false}>
            <Box className="section_container">
              <Box className="header">
                <div>Bizning yo'nalishlarimiz</div>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Box className="box1">
                    <h2> WORK & TRAVEL</h2>
                    <h2> Yozgi tatil vaqtidagi o'quv-amaliyoti</h2>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box className="box1">
                    <h2> FACHPRAKTIKUM</h2>
                    <h2> Oliy ta'lim o'quv amaliyoti</h2>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box className="box1">
                    <h2> WEITERBILDUNG</h2>
                    <h2> Bitiruvchilar uchun malaka oshirish</h2>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </div> */}

        {/* <div className="bg-image-full-2">
          <Container maxWidth={false}>
            <Box className="section_container">
              <Box className="header">
                <div>{t("students.title4")}</div>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Box className="box1">
                    <h2>{t("students.list5.text1")}</h2>
                    <div
                      className="list_container"
                      style={{ color: "black", textAlign: "left" }}
                    >
                      <ul>
                        <li>{t("students.list5.text2")}</li>
                        <li>{t("students.list5.text3")}</li>
                        <li>{t("students.list5.text4")}</li>
                        <li>{t("students.list5.text5")}</li>
                        <li>{t("students.list5.text6")}</li>
                        <li>{t("students.list5.text7")}</li>
                      </ul>
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="box1">
                    <h2>{t("students.list6.text1")}</h2>
                    <div
                      className="list_container"
                      style={{ color: "black", textAlign: "left" }}
                    >
                      <ul>
                        <li>{t("students.list6.text2")}</li>
                        <li>{t("students.list6.text3")}</li>
                        <li>{t("students.list6.text4")}</li>
                        <li>
                          {t("students.list6.text5")}
                          {", " + t("students.list6.text6")}
                        </li>
                        <li>{t("students.list6.text7")}</li>
                      </ul>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </div> */}

        <Container maxWidth="lg">
          <Box className="section_container">
            <Box className="title">
              <div>
                {t("students.subtitle5")}
                <div>
                  <a
                    href="mailto: student@jas-vermittlung.de"
                    target="_blank"
                    rel="noreferrer"
                  >
                    student@jas-vermittlung.de
                  </a>
                </div>
              </div>
            </Box>
          </Box>
        </Container>

        <Container maxWidth="lg">
          <Box className="section_container">
            <Box className="title">
              <div> {t("students.subtitle6")}</div>
            </Box>
          </Box>
        </Container>

        <Box className="btn_container">
          <JButton
            type="primary"
            text="students.registration"
            to="/registration"
          />
        </Box>
      </Box>
    </div>
  );
};

export default Students;
