import React, { useEffect } from "react";

import waiter from "../assets/waiter1.jpg";
import maid from "../assets/hotelmaid1.jpg";
import { useTranslation } from "react-i18next";
import field from "../assets/images/field.jpg";
import traktor from "../assets/images/traktors.jpg";
import student2 from "../assets/images/student2.jpg";
import weiterbildung from "../assets/images/weiterbildung.jpg";
import workandtravel from "../assets/images/workandtravel.jpg";

import photo1 from "../assets/gallery/photo1.jpg";
import photo2 from "../assets/gallery/photo2.jpg";
import photo3 from "../assets/gallery/photo3.jpg";
import photo4 from "../assets/gallery/photo4.jpg";
import photo5 from "../assets/gallery/photo5.jpg";
import photo6 from "../assets/gallery/photo6.jpg";
import photo7 from "../assets/gallery/photo7.jpg";
import photo8 from "../assets/gallery/photo8.jpg";
import photo9 from "../assets/gallery/photo9.jpg";
import photo10 from "../assets/gallery/photo10.jpg";
import photo11 from "../assets/gallery/photo11.jpg";
import photo12 from "../assets/gallery/photo12.jpg";
import photo13 from "../assets/gallery/photo13.jpg";
import photo14 from "../assets/gallery/photo14.jpg";
import photo15 from "../assets/gallery/photo15.jpg";
import photo16 from "../assets/gallery/photo16.jpg";
import photo17 from "../assets/gallery/photo17.jpg";
import photo18 from "../assets/gallery/photo18.jpg";
import photo19 from "../assets/gallery/photo19.jpg";
import photo20 from "../assets/gallery/photo20.jpg";
import photo21 from "../assets/gallery/photo21.jpg";
import photo22 from "../assets/gallery/photo22.jpg";
import photo23 from "../assets/gallery/photo23.jpg";
import photo24 from "../assets/gallery/photo24.jpg";
import photo25 from "../assets/gallery/photo25.jpg";
import photo26 from "../assets/gallery/photo26.jpg";

// import mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import JCard from "../components/JCard";
import JButton from "../components/JButton";
import Container from "@mui/material/Container";
import CardContent from "@mui/material/CardContent";
import CheckCircle from "@mui/icons-material/CheckCircle";
import JGallery from "../components/JGallery";
import Partners from "../components/Partners";

const Employer = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const images = [
    photo1,
    photo2,
    photo5,
    photo6,
    photo7,
    photo8,
    photo9,
    photo10,
    photo11,
    photo12,
    photo13,
    photo14,
    photo15,
    photo16,
    photo17,
    photo18,
    photo19,
    photo20,
    photo21,
    photo22,
    photo23,
    photo24,
    photo25,
    photo26,
  ];

  return (
    <div>
      <Box id="employer" className="main_container">
        <Container maxWidth="lg">
          <Box className="page_title">
            {/* <h2>Wir machen Ihr Unternehmen noch leistungsfähiger!</h2> */}
            <h2>{t("employer.title")}</h2>

            <div className="text_container">{t("employer.text")}</div>
          </Box>

          <Box className="section_container">
            <Box className="title">
              <div>{t("employer.title1")}</div>
            </Box>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <Card className="card">
                  <Box className="card_black_cover"></Box>
                  <img
                    src={student2}
                    alt="student"
                    title="student"
                    style={{ height: "auto", width: "100%" }}
                  />
                  <CardContent className="card_content">
                    <div className="card_title">{t("employer.farm")}</div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={4}>
                <Card className="card">
                  <Box className="card_black_cover"></Box>
                  <img
                    src={maid}
                    alt="student"
                    title="student"
                    style={{ height: "auto", width: "100%" }}
                  />
                  <CardContent className="card_content">
                    <div className="card_title">{t("employer.hotel")}</div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={4}>
                <Card className="card">
                  <Box className="card_black_cover"></Box>
                  <img
                    src={waiter}
                    alt="student"
                    title="student"
                    style={{ height: "auto", width: "100%" }}
                  />
                  <CardContent className="card_content">
                    <div className="card_title">{t("employer.restaurant")}</div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>

          <Box className="section_container">
            <Box className="title">
              <div>{t("employer.title2")}</div>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <JCard
                  image={workandtravel}
                  title="WORK & TRAVEL"
                  subtitle="employer.subtitle1"
                  to={false}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <JCard
                  image={field}
                  title="FACHPRAKTIKUM"
                  subtitle="employer.subtitle2"
                  to={false}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <JCard
                  image={traktor}
                  title="FACHPRAKTIKUM"
                  subtitle="employer.subtitle3"
                  to={false}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <JCard
                  image={weiterbildung}
                  title="WEITERBILDUNG"
                  subtitle="employer.subtitle4"
                  to={false}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="section_container">
            <Box className="title">
              <div>{t("home.advantages.title")}</div>
            </Box>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <div className="list_container">
                  <ul>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("home.advantages.text1")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("home.advantages.text2")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("home.advantages.text3")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("home.advantages.text4")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("home.advantages.text5")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("home.advantages.text6")}
                    </li>
                  </ul>
                </div>
              </Grid>
              {/* <Grid item md={4}>
                <Box className="img_container">
                  <img src={hotelMaid} alt="student" title="student" />
                </Box>
              </Grid> */}
            </Grid>
          </Box>
          <Box className="section_container">
            <Box className="title">
              <div>{t("home.requirement.title")}</div>
            </Box>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <div className="list_container">
                  <ul>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("home.requirement.text1")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("home.requirement.text2")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("home.requirement.text3")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("home.requirement.text4")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("home.requirement.text5")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("home.requirement.text6")}
                    </li>
                  </ul>
                </div>
              </Grid>
              {/* <Grid item md={4}>
                <Box className="img_container">
                  <img src={contract} alt="student" title="student" />
                </Box>
              </Grid> */}
            </Grid>
          </Box>

          <JGallery images={images} />

          <Partners />

          <Box className="btn_container">
            <JButton type="primary" text="banner.contact" to="/contact" />
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default Employer;
